.container {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #e40000;
}

.info {
  text-align: center;
}

.finePrint {
  font-size: 0.8em;
  color: #666;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
}

/* Responsive styling */
@media (max-width: 600px) {
  .container {
    padding: 5px;
    width: 100%;
  }

  .heading img {
    max-width: 100%;
    height: auto;
  }
}
