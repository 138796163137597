.tableContainer {
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-bottom: 30px;
  border-radius: 8px;
  background-color: #fff;
  overflow-x: auto; /* Added for horizontal scrolling */
}

.heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #e40000;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid lightgray;
  padding: 5px;
}

td.cardName {
  text-align: left;
}

td.months,
td.score {
  text-align: center;
}

td.fees {
  text-align: right;
}

th {
  background-color: #e40000;
  color: white;
  cursor: pointer;
}

th.sortable {
  cursor: pointer;
  user-select: none;
}

.row:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.ineligible {
  color: #999;
}

.detailsRow {
  background-color: #f9f9f9;
}

.detailsContainer {
  padding: 10px;
}

.italics {
  font-style: italic;
  color: lightgray;
}

.expandableRow {
  animation: expandAnimation 0.3s ease-in-out;
}

.button {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #e40000;
  border-radius: 5px;
  background-color: #e40000;
  color: white;
  cursor: pointer;
}

@keyframes expandAnimation {
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 500px;
  }
}

/* Responsive styling */
@media (max-width: 600px) {
  .tableContainer {
    padding: 10px;
  }
}
