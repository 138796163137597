.formContainer {
  background-color: white;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #e40000;
}

.formGroup {
  margin: 20px 0;
}

.formGroup label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input[type="range"],
input[type="number"] {
  width: 100%;
  margin: 10px 0;
}

.chevron {
  text-align: left !important;
}

.collapsibleRow {
  animation: collapseAnimation 0.3s ease-in-out;
}

.button {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #e40000;
  border-radius: 5px;
  background-color: #e40000;
  color: white;
  cursor: pointer;
}

@keyframes collapseAnimation {
  0% {
    opacity: 1;
    max-height: 500px;
  }
  100% {
    opacity: 0;
    max-height: 0;
  }
}

.expandableRow {
  animation: expandAnimation 0.3s ease-in-out;
}

@keyframes expandAnimation {
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 500px;
  }
}

/* Responsive styling */
@media (max-width: 600px) {
  .formContainer {
    padding: 10px;
  }

  .heading img {
    max-width: 100%;
    height: auto;
  }

  .formGroup {
    margin: 10px 0;
  }

  .formGroup label {
    margin-bottom: 5px;
  }

  p,
  ul {
    text-align: left;
    padding: 0 10px;
  }

  ul {
    padding-left: 20px;
  }
}
